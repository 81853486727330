import React from "react"
import Img from "gatsby-image"
import styles from "./styles.module.scss"

const Attorneys = ({ lawyers }) => (
  <section className={styles.attorneys}>
    <div className={styles.lawyer}>
      <div className={styles.avatar}>
        <Img fluid={lawyers[0].avatar.fluid} />
      </div>
      <div className={styles.details}>
        <h3>{lawyers[0].name}</h3>
        <span>{lawyers[0].description}</span>
      </div>
    </div>
    <div className={styles.lawyer}>
      <div className={styles.avatar}>
        <Img fluid={lawyers[1].avatar.fluid} />
      </div>
      <div className={styles.details}>
        <h3>{lawyers[1].name}</h3>
        <span>{lawyers[1].description}</span>
      </div>
    </div>
  </section>
)

export default Attorneys
