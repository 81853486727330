import React from "react"
import { BsArrowRight } from "react-icons/bs"
import { Link } from "gatsby"
import styles from "./styles.module.scss"

const Updates = ({ data, lang }) => (
  <section className={styles.updates}>
    <div>
      <h2>{data.updatesTitle}</h2>
    </div>
    <div className={styles.news}>
      {data.updates.map((update, i) => (
        <div key={i} className={styles.news__card}>
          <h3>{update.title}</h3>
          <p>{update.summary}</p>
          <div className={styles.news__button}>
            <Link
              to={`${lang === "en" ? "/" : "/ka/"}${
                update.slug || update.contentful_id
              }`}
            >
              <button>
                {update.more} <BsArrowRight />
              </button>
            </Link>
          </div>
        </div>
      ))}
    </div>
  </section>
)

export default Updates
