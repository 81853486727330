import { Link } from "gatsby"
import React from "react"
import { BsArrowRight } from "react-icons/bs"
import Attorneys from "./attorneys"
import Img from "gatsby-image"
import { getLangsMenu } from "../../utils/locale"
import styles from "./styles.module.scss"

const Header = ({ data, location, lang }) => {
  const { ka } = getLangsMenu(location)
  const linkToKa = ka.link
  const linkToEn = ka.link.slice(3)

  return (
    <header className={styles.header}>
      <div className={styles.bg}>
        <Img fluid={data.headerImage.fluid} />
      </div>
      {/* nav */}
      <nav>
        <div>
          <Link to={`${lang === "en" ? "/" : "/ka/"}`}>
            <img src={data.general.logo.file.url} alt="logo" />
          </Link>
        </div>
        <ul>
          <li>
            <Link to={linkToEn}>en</Link>
          </li>
          <li>
            <Link to={linkToKa}>ge</Link>
          </li>
          {/* <li>
            <Link to="/about">about</Link>
          </li>
          <li>
            <Link to="/professionals">professionals</Link>
          </li>
          <li>
            <Link to="/practice">practice areas</Link>
          </li>
          <li>
            <Link to="/updates">updates</Link>
          </li>
          <li>
            <Link to="/contact">contact</Link>
          </li> */}
        </ul>
      </nav>
      {/* hero */}
      <div className={styles.hero}>
        <span>
          <h1>{data.header}</h1>
        </span>
        <span>
          <Link to="/about">
            <button>
              <div>{data.aboutUsButtonText}</div> <BsArrowRight />
            </button>
          </Link>
        </span>
      </div>
      {/* attorneys */}
      <span className={styles.attorneys__title}>{data.lawyersTitle}</span>
      <Attorneys lawyers={data.lawyers} />
    </header>
  )
}

export default Header
