import React, { useState } from "react"
import { BsArrowRight } from "react-icons/bs"
import styles from "./styles.module.scss"

const Contact = ({
  data,
  namePlaceholder,
  phonePlaceholder,
  mailPlaceholder,
}) => {
  const [name, setName] = useState("")
  const [email, setMail] = useState("")
  const [phone, setPhone] = useState("")

  const onSubmit = e => {
    e.preventDefault()
    if (!name && !email && !phone) return

    const data = { name, email, phone }
    const url = "/.netlify/functions/send"
    fetch(url, {
      method: "POST", // *GET, POST, PUT, DELETE, etc.
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })

    setName("")
    setMail("")
    setPhone("")
  }

  const onChangeName = e => {
    setName(e.target.value)
  }

  const onChangeMail = e => {
    setMail(e.target.value)
  }

  const onChangePhone = e => {
    setPhone(e.target.value)
  }

  return (
    <section className={styles.contact}>
      <h3>{data.contactTitle}</h3>
      <div>
        <span className={styles.gold}>P</span>
        <a href={`tel:${data.general.contactPhone}`} title="call us">
          {data.general.contactPhone}
        </a>
      </div>
      <div>
        <span className={styles.gold}>E</span>
        <a href={`mailto:${data.general.contactEmail}`} title="mail us">
          {data.general.contactEmail}
        </a>
      </div>
      <form>
        <input
          onChange={onChangeName}
          value={name}
          placeholder={namePlaceholder}
          type="text"
        />
        <input
          onChange={onChangePhone}
          value={phone}
          placeholder={phonePlaceholder}
          type="text"
        />
        <input
          onChange={onChangeMail}
          value={email}
          placeholder={mailPlaceholder}
          type="email"
        />
        <button type="submit" onClick={onSubmit}>
          {data.submitButtonText} <BsArrowRight />
        </button>
      </form>
    </section>
  )
}

export default Contact
