import React from "react"
import styles from "./styles.module.scss"

export default function footer({ data }) {
  return (
    <footer className={styles.footer}>
      <div>
        <div className={styles.logo}>
          <img src={data.general.logo.file.url} alt="logo" />
        </div>
        <div>
          <span className={styles.gold}>E</span>
          <a href={`mailto:${data.general.contactEmail}`} title="mail us">
            {data.general.contactEmail}
          </a>
        </div>
      </div>
      <div className={styles.addresses}>
        <div>
          <h3>{data.locationTitle}</h3>
        </div>
        <div>
          <div className={styles.location}>
            <div className={styles.name}>
              <span />
              <span>{data.location}</span>
            </div>
            <div className={styles.number}>
              <span className={styles.gold}>P</span>
              <a href={`tel:${data.general.contactPhone}`} title="call us">
                {data.general.contactPhone}
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
