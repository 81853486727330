import React from "react"
import styles from "./styles.module.scss"
import { BsArrowUp, BsArrowDown } from "react-icons/bs"
import Bg from "./sidebarBg"

const Header = ({ lang }) => (
  <aside className={styles.aside}>
    <div className={styles.wrapper}>
      <Bg />
      <div className={styles.aside__contents}>
        {/* social icons */}
        {/* social icons */}
        <div className={styles.current}>
          <span>{lang === "ka" ? "მთავარი" : "Home"}</span>
        </div>
        <div className={styles.arrows}>
          <span>
            <BsArrowUp />
          </span>
          <span>
            <BsArrowDown />
          </span>
        </div>
      </div>
    </div>
  </aside>
)

export default Header
